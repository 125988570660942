// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-list-js": () => import("/opt/build/repo/src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-template-js": () => import("/opt/build/repo/src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-portfolio-template-js": () => import("/opt/build/repo/src/templates/portfolio-template.js" /* webpackChunkName: "component---src-templates-portfolio-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-calculator-js": () => import("/opt/build/repo/src/pages/calculator.js" /* webpackChunkName: "component---src-pages-calculator-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("/opt/build/repo/src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */)
}

